
$--color-primary: #CE0037;
$--color-success: #66DC71;
$--color-danger: #e32112;


//$--color-text-regular: #565655;
//$--color-text-secondary: #b1b1b2;

$button-text-color: #ffffff;
$button-disabled-text-color: #ffffff;

$--button-primary-font-color: $button-text-color;
$--button-danger-font-color: $button-text-color;
$--button-default-font-color: $--color-primary;
$--button-disabled-font-color: $button-disabled-text-color;
$--button-danger-font-color: $button-text-color;
$--button-info-font-color: $button-text-color;


$--border-radius-base: 0;
$--card-border-radius: 0;
$--tag-border-radius: 0;

$--editable-border: 1px dashed #b9b9b9;